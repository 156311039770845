<!--
 * @description  : 登录页面
-->
<template>
  <div class="login-container">
    <el-form ref="loginFormRef" class="login-form" :model="loginForm" :rules="loginRules">
      <div class="title-container">
        <img class="title" src="../../assets/imgs/logo.png" alt="" />
      </div>

      <el-form-item prop="username" :label="t('user_name')">
        <el-input
          :placeholder="t('user_name')"
          v-model="loginForm.username"
          name="username"
          type="text"
        >
        </el-input>
      </el-form-item>

      <el-form-item prop="password" :label="t('password')">
        <el-input
          :placeholder="t('password')"
          v-model="loginForm.password"
          type="password"
          show-password
        >
        </el-input>
      </el-form-item>
      <el-button type="primary" @click="handleLogin" style="width: 100%; margin-top: 30px">
        {{ t('login') }}
      </el-button>
      <div class="orDiv" v-show="false">
        <span>{{ t('or') }}</span>
      </div>
      <el-button
        type="info"
        @click="gotoRegister"
        style="
          width: 100%;
          margin-top: 30px;
          margin-left: unset;
          color: var(--font-color);
          background: var(--bg-button-cancel);
        "
        v-show="false"
      >
        {{ t('to_register') }}
      </el-button>
    </el-form>
  </div>
</template>
<script setup>
import { ref, onActivated } from 'vue'
import { validatePassword } from './rule'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const loading = ref(false)
const loginFormRef = ref(null)
const store = useStore()
const handleLogin = () => {
  loginFormRef.value.validate(valid => {
    if (!valid) return
    loading.value = true
    store
      .dispatch('user/login', loginForm.value)
      .then(() => {
        loading.value = false
      })
      .catch(err => {
        console.error(err)
        loading.value = false
      })
  })
}

const router = useRouter()
const gotoRegister = () => {
  loginFormRef.value.resetFields()
  router.push({ path: '/register' })
}

// 处理密码框文本显示状态
// const passwordType = ref('password')
// const onChangePwdType = () => {
//   if (passwordType.value === 'password') {
//     passwordType.value = 'text'
//   } else {
//     passwordType.value = 'password'
//   }
// }

/**
 * @description: 数据源
 */
const loginForm = ref({
  username: '',
  password: '' // 'test@123'
})

/**
 * @description: 验证规则∏
 */
const loginRules = ref({
  username: [
    {
      required: true,
      trigger: 'blur',
      message: t('user_name') + t('must_be_filled')
    }
  ],
  password: [
    {
      required: true,
      trigger: 'blur',
      validator: validatePassword()
    }
  ]
})

onActivated(() => {
  loginFormRef.value.resetFields()
})
</script>
<style lang="scss" scoped>
$cursor: #fff;

.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 110px 35px 0;
    margin: 0 auto;
    overflow: hidden;

    ::v-deep .el-form-item {
      flex-direction: column;
      align-items: flex-start;
      .el-form-item__content {
        width: 100%;
        font-size: 20px;
      }
      .el-form-item__label {
        font-size: 20px;
      }
    }

    ::v-deep .el-input {
      display: inline-block;
      width: 100%;
      height: 66px;

      .el-input__inner {
        height: 66px;
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid transparent;
        border-radius: 4px;
        -webkit-appearance: none;
        caret-color: $cursor;
      }
    }
    .el-button {
      height: 66px;
      font-weight: 700;
    }
  }

  .title-container {
    position: relative;
    display: flex;
    justify-content: center;
    .title {
      margin-bottom: 72px;
    }
  }

  .orDiv {
    color: var(--font-color);
    font-size: 20px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 1440px), (max-height: 937px) {
  .login-container {
    .title-container {
      .title {
        margin-bottom: 32px;
      }
    }
    .login-form {
      padding: 72px 35px 0;
      ::v-deep .el-input {
        height: 47px;
        .el-input__inner {
          height: 47px;
        }
      }
      ::v-deep .el-form-item .el-form-item__content,
      ::v-deep .el-form-item .el-form-item__label,
      .orDiv {
        font-size: 14px;
      }
      .el-button {
        height: 40px;
      }
    }
  }
}
</style>
